
.SubscriptionPlan__main-section-container{

}
    .SubscriptionPlan__title-container{

    }
        .SubscriptionPlan__title{
            color: #000;
            text-align: center;
            font-family: 'Montserrat', sans-serif;
            font-size: 3.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.05rem; 
            margin-top: 2em;
            margin-bottom: 1.5em;
        }
    .SubscriptionPlan__subscription-plans-container{
        display: flex;
    }

    .SubscriptionPlan__customer-service-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 2.5em;
        margin-bottom: 1.5em;
    }







@media (max-width: 575.98px) {
    .SubscriptionPlan__main-section-container{
        /* border: 3px solid red; */
    }
        .SubscriptionPlan__title-container{
            /* border: 3px solid green; */
            color: #000;
            text-align: center;
            font-family: 'Montserrat', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 2.05rem; 
            margin-top: 1em;
            margin-bottom: 1.5em;
        }
    .SubscriptionPlan__subscription-plans-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: .5em;
        padding-right: .5em;
    }
    .SubscriptionPlan__title{
        
          /*  border: 3px solid green; */
            color: #000;
            text-align: center;
            font-family: 'Montserrat', sans-serif;
            font-size: 2.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 3rem; 
            margin-top: 1em;
            margin-bottom: 1.5em;
           
    }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .SubscriptionPlan__subscription-plans-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .SubscriptionPlan__title-container{
        padding-left: 1em;
        padding-right: 1em;
    }
    .SubscriptionPlan__title{
        
        /* border: 3px solid green; */
         color: #000;
         text-align: center;
         font-family: 'Montserrat', sans-serif;
         font-size: 2.5rem;
         font-style: normal;
         font-weight: 700;
         line-height: 3rem; 
         margin-top: 1em;
         margin-bottom: 1.5em;
         
        
 }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .SubscriptionPlan__main-section-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
    .SubscriptionPlan__subscription-plans-container{
        flex-direction: row;
        justify-content: center;
        margin-left: 1.8em;
        padding: 0;
    }
    .SubscriptionPlan__title{
        line-height: 3.5rem; 
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .SubscriptionPlan__subscription-plans-container{
        flex-direction: row;
        justify-content: center;
        margin-left: 1.8em;
        padding: 0;
        
    }
    .SubscriptionPlan__title-container{
        /* border: 3px solid green; */
    }
    .SubscriptionPlan__title{
        /* border: 3px solid red; */
        margin-left: .5em;
        margin-right: .5em;
        line-height: 1em;
    }
}

@media (min-width: 1200px) {
    .SubscriptionPlan__subscription-plans-container{
        flex-direction: row;
        justify-content: center;
        align-content: center;
    }
}