
.SubscriptionCard__main-card-container{
    /* border: 6px solid red; */
    width: 100%;
    max-width: 20em;
    min-width: auto;
    margin-top: 1em;
    margin-left: 1.5em;
    margin-right: 1.5em;

    background-color: #FFFFFF;
    border-radius: 1.25em;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 30px 20px 30px 20px;
}
    .SubscriptionCard__inner-card-container{
       /*  border: 3px solid blue; */
    }
  
        .SubscriptionCard__card-title{
           /*  border: 3px solid green;  */ 
        }
            .SubscriptionCard__card-title h1{
                /* border: 3px solid black; */
                word-break: break-word;
                margin-bottom: 0;
                font-family: 'Montserrat', sans-serif;
                font-size: 3rem;
                font-weight: 700;
                color: var(--primary-color);
                text-transform: uppercase;
                text-align: center;
            }
        .SubscriptionCard__card-trial-info{
            /* border: 3px solid red; */
            margin-top:.5em;
            margin-bottom: .5em;
        }
            .SubscriptionCard__card-trial-info p{
                text-align: center;
                margin-bottom: 0;
                font-size: 1.3rem;
                font-family: 'Lato', sans-serif;
                font-weight: 400;
            }
        .SubscriptionCard__card-price{
           /*  border: 3px solid green; */
            margin-top: 2em;
            margin-bottom: 1em;
        }
            .SubscriptionCard__card-price p{
                /* border: 3px solid blue; */
                margin-bottom: 0;
                text-align: center;
            }
                .SubscriptionCard__dolar-price{
                    color: #C2C3C3;
                    text-align: right;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 4.5rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1.05rem; 
                    text-transform: uppercase;
                    text-decoration: line-through;

                }
                .SubscriptionCard__cents-price{
                    color: #C2C3C3;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 2rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1.05rem; 
                    text-transform: uppercase;
                    margin-bottom: 3em;
                    text-decoration: line-through;
                }
        .SubscriptionCard__card-description{
            /* border: 3px solid green; */
            margin-bottom: .5em;
            height: 5em;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
            .SubscriptionCard__card-description p{
                /* border: 3px solid red; */
                margin-bottom: 0;
                font-family: 'Lato', sans-serif;
                font-size: 2rem;
                line-height: 2.37rem;
                font-weight: 300;
                text-align: center;
                width: 100%;
            }
        .SubscriptionCard__button-container{
           /*  border: 3px solid yellow; */
        }
            .SubscriptionCard__button{
               /*  border: 3px solid blue; */
                display: flex;
                flex-direction: column;
                align-items: center;
            }
                .SubscriptionCard__button button {
                    /* border: 3px solid green; */
                    font-family: 'Montserrat', sans-serif;
                    font-size: 1.5;
                    font-weight: 700;
                    text-align: center;
                    line-height: 1.5rem;
                    background-color: #ffffff;
                    color: var(--primary-color);
                    border: 1px solid var(--primary-color);
                    border-radius:10px;
                    padding-left: 2em;
                    padding-right: 2em;
                    padding-top: .5em; 
                    padding-bottom: .5em;
                    text-transform: uppercase;
                    width: 100%;
                    max-width: 10em;
                }
  
  .SubscriptionCard__subscription-definition-container p{
        font-family: 'Lato', sans-serif;
        font-size: 1.1rem ;
        font-weight: 300;
        color: #000000;
        line-height: 2rem;
        padding-top: 1em ;
  }
  .SubscriptionCard__action-button:hover{
    color: #ffffff;
    background-color: var(--primary-color);
  }
  /*=========== RESPONSIVE ============*/
  
  @media (max-width: 575.98px) {
  .SubscriptionCard__main-card-container{
    margin: 0;
    margin-top: 2em;
  }
      
  }
  
  
  @media (min-width: 576px) and (max-width: 767.98px) {
      
  }
  
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    .SubscriptionCard__main-card-container{
       /*  border: 3px solid red; */
       width: 100;
       width: 20em;
    }
    .SubscriptionCard__card-title h1{
        line-height: 2.5rem;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .SubscriptionCard__main-card-container{
        width: 100%;
        max-width: 20em;
  }
  }
  
  
  @media (min-width: 1200px) {
    .SubscriptionCard__card-container {
        /* border: 3px solid green; */
        margin: 0;
        height: auto;
    }
    .SubscriptionCard__card-title{
        /* border: 3px solid black; */
        /* margin-top: 3.5em; */
    }
    .SubscriptionCard__main-card-container{
        width: 100%;
        max-width: 20em;
  }
  }