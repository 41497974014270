
.SectionDivider__section-container{
    /* border: 2px solid red; */
    background-color: var(--primary-color);
    padding: .5em;
    /* border-radius: 0em 0em 1.25em 1.25em; */
}
    .SectionDivider__inner-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
    }
        .SectionDivider__title-container{
            /* border: 3px solid blue; */
            display: flex;
            justify-content: center;
            margin-top: 1.5em;
            margin-bottom: 3.5em;

        }   
            .SectionDivider__title{
                /* border: 3px solid red; */
                color: #FFF;
                text-align: center;
                font-family: "Montserrat", sans-serif;
                font-size: 2.25rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.05rem; 
            }
        .SectionDivider__icons-container{
                /* border: 3px solid rgb(255, 255, 255); */
                display: flex;
        }
            .SectionDivider__inner-icons-container p {
                color: rgb(255, 255, 255);
                text-align: center;
                font-family: "Montserrat", sans-serif;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 200;
                line-height: normal;
                text-transform: uppercase;
                margin-bottom: 0;
                margin-top: 1em;
                /* border: 3px solid red; */
                            }
            .SectionDivider__inner-icons-container{
                /* border: 3px solid purple; */
                display: flex;
                
            }
                .SectionDivider__icon-container{
                    /* border: 3px solid green; */
                    width: 4em;
                    
                }
                    .SectionDivider__icon{
                        /* border: 3px solid rgb(0, 0, 0); */
                        width: 100%;
                    }




@media (max-width: 575.98px) {
  /*  .SectionDivider__section-container{
        padding-top: 2em;
        padding-bottom: 2em;
      
   }.SectionDivider__inner-container{
        display: flex;
        flex-direction: column;
        padding: 1em;
       
    }
        .SectionDivider__icons-container{
            display: flex;
            flex-direction: column;
        }
     .SectionDivider__text-container{
        width: 100%;
     }

     .SectionDivider__inner-icons-container p {
        font-size: 1.1rem;
        font-weight: 400;
    }
 */
 .SectionDivider__section-container{
    /* border: 3px solid red; */
 }
    .SectionDivider__inner-container{
        /* border: 3px solid green; */
        width: 100%;
    }   
        .SectionDivider__title-container{
            /* border: 3px solid purple; */
        }
            .SectionDivider__title{
               /*  border: 3px solid green; */
                line-height: 2.5rem;
            } 

        .SectionDivider__icons-container{
            /* border: 3px solid blue; */
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
        }  
            .SectionDivider__inner-icons-container{
                /* border: 3px solid black; */
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                width: 10em;
                height: 8em;
                margin-top: 1.5em;
            }
                .SectionDivider__icon-container{
                   /*  border: 3px solid blue; */
                }
                    .SectionDivider__left-column{
                        /* border: 3px solid red; */
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                    .SectionDivider__right-column{
                        /* border: 3px solid green; */
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                        .SectionDivider__icon-container div{
                            /* border: 3px solid white; */
                        }
                            .SectionDivider__icon{
                               /*  border: 3px solid green; */
                            }
                .SectionDivider__text-container{
                    /* border: 3px solid green; */
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                .SectionDivider__text-container p{
                    /* border: 3px solid red; */
                    width: 100%;
                    margin-top: 0.5em;
                    padding: 0;
                    font-size: 1rem;
                }
       
}


@media (min-width: 576px) and (max-width: 767.98px) {
    /* .SectionDivider__section-container{
        padding-top: 2em;
        padding-bottom: 2em;

      
   }.SectionDivider__inner-container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-left: 2em;
       
    }
        .SectionDivider__icons-container{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
        }
            .SectionDivider__inner-icons-container{
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                
            }
            .SectionDivider__icon-container{ 
            }
            .SectionDivider__icon-container div{
                width: 4em;
            }
            .SectionDivider__icon{
                width: 100%;
            }
            .SectionDivider__text-container{
                width: 100%;
            }
            .SectionDivider__inner-icons-container p {
                font-size: 1rem;
                font-weight: 400;
            } */
            .SectionDivider__section-container{
                /* border: 3px solid rgb(0, 255, 191); */
                height: auto;
             }
                .SectionDivider__inner-container{
                    /* border: 3px solid rgb(14, 14, 14); */
                    width: 100%;

                }   
                    .SectionDivider__title-container{
                       /*  border: 3px solid purple; */
                        padding: 0;
                        margin-top: 1em;
                    }
                        .SectionDivider__title{
                           /*  border: 3px solid green; */
                            line-height: 2.5rem;
                        } 
            
                    .SectionDivider__icons-container{
                        /* border: 3px solid rgb(12, 12, 14); */
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        margin: 0;
                        padding: 0;
                    }  
                        .SectionDivider__inner-icons-container{
                            /* border: 3px solid black; */
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            width: 100%;
                            width: 10em;
                            height: 8em;
                            margin-top: 1em;
                        }
                            .SectionDivider__icon-container{
                                /* border: 3px solid blue; */
                            }
                                .SectionDivider__left-column{
                                    /* border: 3px solid red; */
                                    width: 50%;
                                    height: auto;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                }
                                .SectionDivider__right-column{
                                    /* border: 3px solid green; */
                                    width: 50%;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                }
                                    .SectionDivider__icon-container div{
                                        /* border: 3px solid white; */
                                    }
                                        .SectionDivider__icon{
                                           /*  border: 3px solid green; */
                                        }
                            .SectionDivider__text-container{
                                /* border: 3px solid green; */
                                height: 100%;
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                            }
                            .SectionDivider__text-container p{
                               /*  border: 3px solid red; */
                                width: 100%;
                                margin-top: 0.5em;
                                padding: 0;
                                font-size: 1.10rem;
                            }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .SectionDivider__section-container{
        /* border: 3px solid red; */
        padding-top: 2em;
        padding-bottom: 2em;
        
   }.SectionDivider__inner-container{
        /* border: 3px solid rgb(204, 234, 35); */
        display: flex;
        flex-direction: column;
        align-items: center;
        margin:0;
        padding-left: 3em;
        padding-right: 3em;
       
    }
        .SectionDivider__icons-container{
            /* border: 3px solid blue; */
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
        }
        .SectionDivider__left-column, .SectionDivider__right-column{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            /* border: 3px solid red; */
            width: 100%;
        }
            .SectionDivider__inner-icons-container{
              /*   border: 3px solid rgb(0, 0, 0); */
                width: 20%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .SectionDivider__icon-container{
                /* border: 3px solid green; */
               
            }
            .SectionDivider__icon-container div{
                /* border: 3px solid red; */
                width: 4em;
            }
            .SectionDivider__icon{
                width: 100%;
            }
            .SectionDivider__text-container{
                /* border: 3px solid black; */
                width: 100%;
            }
            .SectionDivider__inner-icons-container p {
                font-size: 1rem;
                font-weight: 400;
            }

}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .SectionDivider__section-container{
        /* border: 3px solid red; */
        padding-top: 2em;
        padding-bottom: 2em;
      
   }.SectionDivider__inner-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
        align-items: center;
       
    }
        .SectionDivider__icons-container{
           /*  border: 3px solid blue; */
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            padding: 0;
            margin: 0;
        }
            .SectionDivider__inner-icons-container{
                /* border: 3px solid rgb(0, 215, 0); */
                width: 15%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0;
                margin: 0;
            }
            .SectionDivider__inner-icons-container p {
                font-size: 1rem;
                font-weight: 400;
            }
            .SectionDivider__left-column, .SectionDivider__right-column{
                display: flex;
                flex-direction: row;
                justify-content:space-around ;
               /*  border: 3px solid green; */
                width: 100%;
            }
            .SectionDivider__icon-container{
                /* border: 3px solid green; */
            }
            .SectionDivider__icon-container div{
             /*    border: 3px solid red; */
            }
            .SectionDivider__text-container{
                /* border: 3px solid blue; */
            }
}

@media (min-width: 1200px) {
    .SectionDivider__section-container{
        /* border: 3px solid red; */
        padding-top: 2em;
        padding-bottom: 2em;
      
   }.SectionDivider__inner-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1em;
       
    }
        .SectionDivider__icons-container{
            /* border: 3px solid blue; */
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            padding: 0;
            margin: 0;
        }
            .SectionDivider__inner-icons-container{
                /* border: 3px solid rgb(0, 215, 0); */
                width: 25%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0;
                margin: 0;
            }

            .SectionDivider__inner-icons-container p {
                font-size: 1rem;
                font-weight: 400;
            }
            .SectionDivider__text-container{
                /* border: 3px solid red; */
                width: 70%;
            }
            .SectionDivider__left-column, .SectionDivider__right-column{
                display: flex;
                flex-direction: row;
                justify-content:space-around ;
                /* border: 3px solid green; */
                width: 100%;
            }
            .SectionDivider__icon-container{
              /*   border: 3px solid green; */
            }
            .SectionDivider__icon-container div{
                /* border: 3px solid red; */
            }
            .SectionDivider__text-container{
              /*   border: 3px solid blue; */
                width: 100%;
            }
                .SectionDivider__text-container p{
                    /* border: 3px solid purple; */
                    text-align: center;
                }
               
}